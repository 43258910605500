import { _x } from '@wordpress/i18n'

export default {
  consent_message: _x(
    'Um diesen Inhalt zu sehen, müssen Sie dem Drittanbieter-Cookie von %s zustimmen!',
    'embed-consent',
    'dhsv_theme'
  ),
  to_privacy_manager_1: _x('Zu den', 'embed-consent', 'dhsv_theme'),
  to_privacy_manager_2: _x(
    'Privatsphäre-Einstellungen',
    'embed-consent',
    'dhsv_theme'
  ),
}
