export default {
  init() {
    // JavaScript to be fired on all pages

    function isInViewport(node) {
      let rect = node.getBoundingClientRect()
      return (
        (rect.height > 0 || rect.width > 0) &&
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    function setCookie(cname, cvalue, exdays = 30) {
      var d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      var expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + '; ' + expires
    }

    function getCookie(cname) {
      var name = cname + '='
      var ca = document.cookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1)
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length)
      }
      return ''
    }

    $(document).ready(function () {
      if (getCookie('postLiked') == 'yes') {
        $('#post-like').addClass('active')
      }

      $('#post-like').on('click', function () {
        let postLike = getCookie('postLiked')
        if (postLike == 'yes') {
          setCookie('postLiked', 'no')
          $(this).removeClass('active')
        } else {
          setCookie('postLiked', 'yes')
          $(this).addClass('active')
        }
      })

      $('li.loadprivacymanagermodal > a').on('click', function (e) {
        e.preventDefault()
        window._sp_.loadPrivacyManagerModal(620682)
      })

      setTimeout(function () {
        const pageForms = $('.gform_wrapper')
        if (pageForms.length) {
          pageForms.each(function () {
            const formButton = $(this).find('.gform_footer button.btn')

            if (formButton.length) {
              formButton.on('click', function () {
                setTimeout(() => {
                  $(this).addClass('form-loading')
                  $(this).attr('disabled', 'disabled')
                }, 50)
              })
            }
          })
        }

        $(document).on('gform_post_render', function (event, form_id) {
          const currentButton = $('#gform_' + form_id).find(
            '.gform_footer button.btn'
          )

          if (currentButton.length) {
            currentButton.removeClass('form-loading')
            currentButton.attr('disabled', false)
            currentButton.on('click', function () {
              setTimeout(() => {
                $(this).addClass('form-loading')
                $(this).attr('disabled', 'disabled')
              }, 50)
            })
          }
        })
      }, 2000)

      /**
       * Custom select
       */
      // $('select').select2({
      //   width: '100%',
      //   minimumResultsForSearch: Infinity,
      // })
    })

    $(window).trigger('resize')

    $(window).on('scroll', function () {
      /**
       * Group Module shape animation
       */
      let windowTop = $(window).scrollTop()
      $('.wp-block-group-inner').each(function () {
        let block = this
        if (isInViewport(block)) {
          $('.wp-block-group-inner__decor').each(function () {
            let currentElement = $(this),
              elementTop = currentElement.offset().top,
              elementHeight = currentElement.height(),
              viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
              scrolled = windowTop - elementTop + viewPortHeight,
              elementWidth = currentElement.outerWidth(),
              speed = elementWidth > 300 ? -0.09 : -0.29

            currentElement.css({
              transform: `translate3d(0, ${scrolled * speed}px, 0)`,
            })
          })
        }
      })

      /**
       * Group Module line animation
       */
      $('.wp-block-group--line').each(function () {
        let elem = $(this)
        if (isInViewport(this)) {
          let elemHeight = elem.outerHeight(),
            height = $(window).height(),
            r = this.getBoundingClientRect(),
            lineHeight = Math.min(elemHeight, height - r.top),
            linePersent = 0

          linePersent = (lineHeight * 100) / elemHeight

          elem.css('--animate-line', linePersent + '%').addClass('is-animated')
        }
      })
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
