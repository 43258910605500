import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0,
      change: false,
      parentTitle: '',
      parentLink: '',
      parents: [],
      currentMenu: this.props.menuItems,
    }
    this.clickItem = this.clickItem.bind(this)
    this.clickBack = this.clickBack.bind(this)
  }

  backText() {
    let text
    let lang = document.getElementsByTagName('html')[0].lang
    if (lang === 'en-US') {
      text = 'Back'
    } else {
      text = 'Zurück'
    }
    return text
  }

  getMenu(parents = this.state.parents) {
    const { menuItems } = this.props
    if (parents.length > 0) {
      let setMenu = menuItems
      let titles = []
      let links = []
      let active = []
      for (let i = 0; i < parents.length; i++) {
        titles = [
          ...titles,
          setMenu.find(el => el.post_id === parents[i]).title,
        ]
        links = [
          ...links,
          setMenu.find(el => el.post_id === parents[i]).permalink,
        ]
        active = [
          ...active,
          setMenu.find(el => el.post_id === parents[i]).active,
        ]
        setMenu = setMenu.find(el => el.post_id === parents[i]).children
      }
      this.setState({
        parentTitle: titles[titles.length - 1],
        parentLink: links[links.length - 1],
        parentActive: active[active.length - 1],
        currentMenu: setMenu,
      })
    } else {
      this.setState({
        parentTitle: '',
        parentLink: '',
        parentActive: '',
        currentMenu: menuItems,
      })
    }
  }

  clickItem(item) {
    if (item.children.length) {
      const newParents = [...this.state.parents, item.post_id]
      this.setState({
        change: true,
      })
      setTimeout(
        function () {
          this.setState({
            active: item.post_id,
            parents: newParents,
            change: false,
          })
          this.getMenu(newParents)
        }.bind(this),
        500
      )
    } else {
      window.location.href = item.permalink
    }
  }

  clickBack() {
    const setParents = this.state.parents.slice(0, -1)
    this.setState({
      change: true,
    })
    setTimeout(
      function () {
        this.setState({
          active: setParents[setParents.length - 1],
          parents: setParents,
          change: false,
        })
        this.getMenu(setParents)
      }.bind(this),
      500
    )
  }

  render() {
    const {
      active,
      parentTitle,
      parentLink,
      parentActive,
      currentMenu,
      change,
    } = this.state
    const { activeMenu } = this.props
    return (
      <div
        className={'dhsv-mobile-menu' + activeMenu + (change ? ' changed' : '')}
        data-active={active}
      >
        {currentMenu[0].menu_item_parent !== 0 ? (
          <>
            <button onClick={this.clickBack} className="title">
              <span dangerouslySetInnerHTML={{ __html: this.backText() }} />
            </button>
          </>
        ) : null}
        <ul className={'menu'}>
          {currentMenu[0].menu_item_parent !== 0 ? (
            <li className="overview">
              <a href={parentLink} className={parentActive ? 'active' : null}>
                {parentTitle}
              </a>
            </li>
          ) : null}
          {currentMenu.map((menuItem, index) => (
            <li
              key={index}
              className={menuItem.children.length ? 'has-children' : null}
              style={{
                animationDelay: 0.05 + index * 0.05 + 's',
              }}
            >
              <a
                href={menuItem.permalink}
                className={menuItem.active ? 'active' : null}
                dangerouslySetInnerHTML={{ __html: menuItem.title }}
              />
              {menuItem.children.length ? (
                <span
                  onClick={() => this.clickItem(menuItem)}
                  dangerouslySetInnerHTML={{
                    __html:
                      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect width="24" height="24" fill="none"/><g transform="translate(4.482 6.03)"><path d="M14.99.5H0" transform="translate(0 5.538)" fill="none" stroke="currentColor"/><path d="M0,6.016,5.977,0l5.977,6.016" transform="translate(14.99 0) rotate(90)" fill="none" stroke="currentColor"/></g></svg>',
                  }}
                />
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

MobileMenu.propTypes = {
  isMobile: PropTypes.bool,
  menuItems: PropTypes.array,
  topMenu: PropTypes.array,
  activeMenu: PropTypes.string,
  burgerMenu: PropTypes.func,
}

export default MobileMenu
