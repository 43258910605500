import React, { Suspense, lazy } from 'react'

/*
	
	code-split out component, passing translations via props
	so we can have both: code splitting and l10n via @wordpress/i18n
	(because @wordpress/i18n can not translate strings inside split chunks)

*/

const codeSplit = ({ loadComponent, l10n = {}, loadingIndicator = null }) => {
  const CodeSplit = props => {
    const Component = lazy(loadComponent)
    return (
      <Suspense fallback={loadingIndicator}>
        <Component l10n={l10n} {...props} />
      </Suspense>
    )
  }

  return CodeSplit
}

export default codeSplit
