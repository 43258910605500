import React from 'react'
import PropTypes from 'prop-types'

const MainNav = ({ items, className, onItemHoverHandler, onMouseLeave }) => {
  /**
   * Recursively creates child navigation
   * @param items
   * @param maxDepth
   * @param level
   * @returns {boolean}
   */
  const buildSubtree = (menuItems, maxDepth = 0, level = 1) => {
    return (
      menuItems.length > 0 &&
      menuItems.map((item, i) => {
        const id = level + '-' + i
        return (
          <li
            key={id}
            className={`menu-item${item.active ? ' current' : ''}`}
            data-id={id}
            onMouseOver={onItemHoverHandler}
            onMouseOut={onMouseLeave}
          >
            <a
              className="menu-link"
              href={item.permalink}
              dangerouslySetInnerHTML={{ __html: item.title }}
            ></a>
            {item.children.length > 0 &&
              (level < maxDepth || maxDepth == 0) && (
                <ul className="submenu">
                  {buildSubtree(item.children, maxDepth, level + 1)}
                </ul>
              )}
          </li>
        )
      })
    )
  }

  return <ul className={className}>{buildSubtree(items)}</ul>
}

MainNav.propTypes = {
  items: PropTypes.array,
  activeMenu: PropTypes.number,
  onItemHoverHandler: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
}

export default MainNav
