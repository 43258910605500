/* global System */
// register component(s) with react habitat
import codeSplit from 'scripts/higher-order-components/codeSplit'
import l10n from '../EmbedWithConsent/l10n'

export default {
  EmbedYouTube: codeSplit({
    l10n,
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "EmbedYouTube" */
        './index'
      ),
  }),
}
