import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import MainNav from './MainNav'
import MobileMenu from './../MobileMenu'
import './styles.scss'

const Header = ({
  isMD,
  globals: { logo },
  menus: {
    main: mainMenu,
    mobile: mobileMenu,
    header_button,
    header_icon,
    languages,
  },
}) => {
  const [state, setState] = useState({
    activeMenu: false,
  })
  const [openMobile, setOpenMobile] = useState(false)

  const ref = useRef(null)
  const mobileRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchend', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchend', handleClickOutside)
    }
  }, [])

  const handleMenuEnter = key => {
    setState({ activeMenu: key })
  }

  // const handleMenuLeave = () => {
  //   setState({ activeMenu: null })
  // }

  const handleHeaderLeave = () => {
    setState({ activeMenu: null })
  }

  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setState({ activeMenu: null })
    }

    if (
      mobileRef.current &&
      !mobileRef.current.contains(e.target) &&
      state.activeMenu !== 'mobile'
    ) {
      setState({ activeMenu: null })
    }
  }

  const burgerMenu = () => {
    setOpenMobile(!openMobile)
    document.querySelector('body').classList.toggle('mobile-menu-active')
  }

  // Mobile menu
  if (isMD) {
    return (
      <>
        <header ref={mobileRef} className={`header -mobile`}>
          <div className="header__outer">
            <a href={logo.link} className="logo">
              <img src={logo.src.url} alt={logo.src.alt} />
            </a>
            <div className="header__nav">
              {languages && (
                <div
                  className={'header__languages'}
                  dangerouslySetInnerHTML={{ __html: languages }}
                />
              )}
              <button
                className={`burger ${openMobile ? 'is-active' : ''}`}
                onClick={burgerMenu}
              >
                <span />
              </button>
            </div>
          </div>
        </header>
        <MobileMenu
          menuItems={mobileMenu}
          activeMenu={openMobile ? ' open-menu' : ''}
          burgerMenu={burgerMenu}
        />
      </>
    )
  }

  // Desktop menu
  return (
    <header
      ref={ref}
      className={`header -desktop ${state.activeMenu ? 'is-hovered' : ''}`}
      //onMouseLeave={handleHeaderLeave}
    >
      <div className="header__outer">
        <a href={logo.link} className="logo">
          <img src={logo.src.url} alt={logo.src.alt} />
        </a>
        <MainNav
          className="header__nav"
          items={mainMenu}
          onItemHoverHandler={handleMenuEnter}
          onMouseLeave={handleHeaderLeave}
        />
        <div className={'header__right'}>
          {languages && (
            <div
              className={'header__languages'}
              dangerouslySetInnerHTML={{ __html: languages }}
            />
          )}
          {header_button && (
            <div className={'header__btn'}>
              <a
                className={`btn btn--primary ${header_icon ? 'has-icon' : ''}`}
                href={header_button.url}
                target={header_button.target ? '_blank' : '_self'}
                dangerouslySetInnerHTML={{ __html: header_button.title }}
              />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  isMD: PropTypes.bool,
  globals: PropTypes.object,
  menus: PropTypes.object,
}

export default Header
